






















import {Vue, Component, Prop} from "vue-property-decorator";
import MessageNavType from "./indexType";

@Component({name: "MessageNav"})
export default class MessageNav extends Vue implements MessageNavType{
    rightIcon = require("@/assets/icon/Basics/Right.png")

    @Prop(Number)
    hideNum!:number
    get getHideNum(){
        return this.hideNum
    }

    @Prop(Array)
    list!:any[]
    get getList(){
        return this.list || []
    }
}
