

















import {Vue, Component} from "vue-property-decorator";
import MessageListType from "./MessageList";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import MessageListNav from "@/components/MessageAndFriend/HeadNav/index.vue"
import MessageListBox from "@/views/Message/MessageList/components/List/index.vue"
import {MessageStore} from "@/views/Message/Store";
import { NavListType } from '../../Friend/MyFriends/Model/indexType';
import { MessageNavList } from './Model';

@Component({name: "MessageList",components:{ HeadTop,MessageListNav,MessageListBox }})
export default class MessageList extends ZoomPage implements MessageListType{
    addIcon = require("@/assets/icon/Basics/Add.png")
    navList:NavListType[] = MessageNavList

    mounted(){
        this.loadingShow = false
    }

    get getFriendHideNum(){
        return MessageStore.getNewestMessageFriendStatusNum
    }
}
