
















import {Vue, Component, Watch} from "vue-property-decorator";
import MessageListBoxType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import ZoomPage from "@/impView/PageSafety";
import MessageListItem from "@/views/Message/MessageList/components/item/index.vue";
import {MessageStore} from "@/views/Message/Store";
import {MessageListStore} from "@/views/Message/MessageList/Store";
import { UpMessageList } from '@/views/Message/Server';
import {filterList, filterNum, filterUnread} from "@/Api/Socket/util";
import Storage from "@/util/Storage";

@Component({name: "MessageListBox",components:{ PullDownUpList,ListBottom,MessageListItem }})
export default class MessageListBox extends ZoomPage implements MessageListBoxType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    activated(){
        this.List = this.getChatList
    }

    handleGetFilterList(e:any){
        let { data,index } = e
        let List = filterNum(this.List,index)
        // 进行本地缓存
        Storage.SetData_?.("applyList",JSON.stringify(List))
        MessageStore.SetNewestMessageList(List)
        // 计算未读数量
        let socket = MessageStore.getMessageSocket
        socket.handleUnreadNum()
    }

    handlePullDown(){
        let upData = MessageListStore.getMessageListUpData;
        upData.pageNo = 1;
        MessageListStore.SetMessageListUpData(upData)
        this.StopUp = false
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let upData = MessageListStore.getMessageListUpData;
        upData.pageNo += 1;
        MessageListStore.SetMessageListUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        UpMessageList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < MessageListStore.getMessageListUpData.pageSize )this.StopUp = true;
            let List:any[] = filterList(MessageStore.getNewestMessageList,(res.userChatData || []))
            let Num = filterUnread(List)
            Storage.SetData_?.("chatList",JSON.stringify(List))
            Storage.SetData_?.("chatListNum",Num + '')
            MessageStore.SetNewestMessageList( List );
            MessageStore.SetNewestMessageNum(Num)
        })
    }

    get getListClassName(){
        let { footerNav = "" } = this.$route.query
        let { barShow } = this
        if ( footerNav && barShow ){
            return "MessageListBoxFooterNav"
        }else if ( barShow == false && (!footerNav) ){
            return "MessageListBoxNav"
        }else {
            return "MessageListBox"
        }
    }

    get getChatList(){ return MessageStore.getNewestMessageList }
    @Watch("getChatList")
    changeChatList(newVal:any[]){
        this.List = newVal
    }

}
