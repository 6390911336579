



























import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import MessageListItemType from "./indexType";
import { computedTime } from "@/util/computedTime";

@Component({name: "MessageListItem"})
export default class MessageListItem extends Vue implements MessageListItemType{

    handleToPath(){
        let { groupId = "",groupName = "",chatStatus = "",friendId = "",friendName = "" } = this.getData
        if ( groupId && groupName || chatStatus == 5 ){
            this.$router.push({
                name:"chat",
                query:{
                    title:groupName || '',
                    id:groupId || '',
                    state:"groupChat"
                }
            })
        }else{
            this.$router.push({
                name:"chat",
                query:{
                    title:friendName || '',
                    id:friendId || '',
                    state:"chat"
                }
            })
        }
        this.pullFilterList();
    }

    get getPhoto(){
        if ( this.getData && this.getData.chatStatus && this.getData.chatStatus == 5 )
            return this.getData.groupUrl && this.getData.groupUrl || require("@/assets/icon/Basics/default.png");
        else
            return this.getData && this.getData.headImg && this.getData.headImg || require("@/assets/icon/Basics/default.png");
    }

    get getTime(){
        return this.getData && computedTime(this.getData.sentTime || new Date())
    }

    get getContent(){
        if ( this.getData && this.getData.type < 2 ){
            return this.getData.content
        }else if (this.getData.type == 2){
            return "图片"
        }else{
            return this.getData.content
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data || {} }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index || 0 }

    @Emit("pullFilterList")
    pullFilterList(){
        return {
            data:this.getData,
            index:this.getIndex
        }
    }
}
